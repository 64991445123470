*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --main-color: #fbbe5b;
    --main-bkg: rgb(17, 14, 37);
    --sec-bkg: rgb(36, 32, 68);
    --sec-color: rgb(99, 118, 180);
    --text-color: rgb(214, 205, 205);
    --container-width-lg: 80%;
    --container-width-md: 90%;
    --transition: all 500ms ease;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: "Montserrat", sans-serif;
    color: var(--text-color);
    line-height: 1.7;
    overflow-x: hidden;
    background: var(--main-bkg);
}

.container {
    width: var(--container-width-lg);
    max-width: 1920px;
    margin-inline: auto;
}

h1,
h2,
h3,
h4,
h5 {
    line-height: 1.2;
    color: var(--text-color);
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2rem;
}

a {
    color: var(--main-color);
}

img {
    display: block;
    object-fit: cover;
    width: 100%;
}

.btn {
    color: var(--text-color);
    width: fit-content;
    margin: 0 auto;
    padding: 0.9rem 2rem;
    background: var(--sec-color);
    border-radius: 1.5rem;
    transition: var(--transition);
}

.btn:hover {
    background: var(--main-color);
    color: #fff;
}

.btn.lg {
    padding: 1.2rem 3rem;
    border-radius: 2rem;
    font-size: 1.1rem;
}

.btn.sm {
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
}

.btn.sm:hover {
    background: #fff;
}

.section__head {
    display: flex;
    align-items: center;
    gap: 1rem;
}

section {
    padding: 5rem 0;
}

.section__head span {
    padding: 1.8rem;
    border-radius: 1rem;
    background: rgb(36, 32, 68);
    color: var(--main-color);
    font-size: 1.5rem;
}

.card {
    background: rgb(36, 32, 68);
    border: 2px solid transparent;
    text-align: center;
    padding: 3rem 2rem;
    border-radius: 0 3rem 0 3rem;
    transition: var(--transition);
}

.card:hover {
    background: transparent;
    border-color: #e0ddd8;
    cursor: default;
}

.card span {
    width: 3rem;
    height: 3rem;
    background: var(--sec-color);
    padding: 0.6rem;
    font-size: 1.5rem;
    display: grid;
    place-items: center;
    margin-inline: auto;
    margin-bottom: 1.5rem;
    border-radius: 1rem;
    transition: var(--transition);
}

.card:hover span {
    background: var(--main-color);
    color: #fff;
}

.card small {
    margin-top: 1rem;
    display: block;
    font-weight: 300;
}

.header {
    height: 30rem;
    overflow: hidden;
    border-bottom: 2px solid var(--main-bkg);
}

.header__container {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    place-items: center;
    background: black;
}

.header__container-bg {
    position: absolute;
    width: 100%;
}

.header__container-bg img {
    opacity: 0.5;
    background-position: center;
}

.header__content {
    position: relative;
    width: 40%;
    margin: 0 auto;
    text-align: center;
    color: var(--text-color);
}

.header__content h4 {
    margin-bottom: 1rem;
}

.header__content p {
    color: rgba(255, 255, 255, 0.7);
}


/*========================== 
media
=============================*/

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.6rem;
    }
    section {
        padding-top: 0 !important;
    }
    .header__content {
        width: 76%;
    }
}

@media screen and (max-width: 600px) {
    h1 {
        font-size: 2.2rem;
        line-height: 1.3;
    }
    section {
        margin-top: 7rem;
        padding: 0;
    }
    .header {
        height: 38vh;
    }
    .header__content {
        width: var(--container-width-md);
        padding-top: 5rem;
    }
    .header__content p {
        font-size: 0.85rem;
    }
}