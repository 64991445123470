.about__section-image {
    transform: skew(15deg);
    transition: var(--transition);
}

.about__section-image img {
    border-radius: 50%;
}

.about__section-image:nth-child(2) {
    transform: skew(-15deg);
    transition: var(--transition);
}

.about__section-image:hover {
    transform: skew(0deg);
}

.about__section-content h1 {
    margin-bottom: 2rem;
}

.about__section-content p {
    margin-bottom: 1rem;
}

.about__story-container,
.about__mission-container {
    display: grid;
    grid-template-columns: 40% 50%;
    gap: 10%;
}

.about__Vision-container {
    display: grid;
    grid-template-columns: 50% 40%;
    gap: 10%;
}

@media screen and (max-width: 1024px) {
    section {
        margin-top: 5rem;
    }
    .about__section-image {
        width: 60%;
        margin-inline: auto;
    }
    .about__section-content {
        margin-bottom: 1.2rem;
    }
    .about__mission-container,
    .about__story-container,
    .about__Vision-container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }
    .about__Vision .about__section-image {
        grid-row: 1;
    }
}

@media screen and (max-width: 600px) {
    .about__section-image {
        width: 80%;
        margin-inline: auto;
    }
    .about__mission-container,
    .about__story-container,
    .about__Vision-container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}