.main__header {
    width: 100vw;
    height: calc(100vh - 3rem);
    display: grid;
    place-items: center;
    margin-top: 3rem;
}

.main__header-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
}

.main__header-left {
    margin-top: -3rem;
}

.main__header-left h4 {
    margin-bottom: 1rem;
    font-weight: 100;
    color: var(--main-color);
}

.main__header-left p {
    margin: 1rem 0 3.5rem;
    font-size: 1.1rem;
}

.main__header-right {
    display: grid;
    place-items: center;
    position: relative;
}

.main__header-circle {
    width: 26rem;
    height: 26rem;
    background: linear-gradient(75deg, #fbbe5b, transparent);
    border-radius: 50%;
    position: absolute;
    transition: var(--transition);
}

.main__header-iamge {
    position: relative;
}

.main__header-right:hover .main__header-circle {
    filter: blur(15px);
}


/*=================
       programs
==================*/

.programs {
    margin: 1rem 0 2rem 0;
}

.programs__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    margin-top: 4rem;
}

.programs__program a {
    margin-top: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.programs__program:hover a {
    background: var(--main-color) !important;
}


/*=================
      values
==================*/

.values__container {
    display: grid;
    grid-template-columns: 38% 50%;
    gap: 12%;
    margin-top: 5rem;
}

.values__img {
    filter: saturate(0.25);
    transition: var(--transition);
}

.values__img:hover {
    filter: saturate(1);
}

.values__right>p {
    margin: 1.5rem 0 5rem;
}

.values__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.4rem 2.4rem;
}

.card.values__value {
    padding-top: 3rem;
    text-align: left;
    position: relative;
}

.card.values__value span {
    position: absolute;
    top: -1.5rem;
}


/*========================== 
FAQs
=============================*/

.faqs__wrapper {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 5rem;
    align-items: flex-start;
}

.faq {
    background: var(--sec-bkg);
    padding: 2rem;
    border-radius: 1rem;
    cursor: pointer;
}

.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
}

.faq__icon {
    background: transparent;
    color: var(--text-color);
    font-size: 1.5rem;
}

.faq p {
    margin-top: 1.5rem;
}


/*========================== 
Testimonials
=============================*/

.testimonials {
    width: 60vw;
    margin-inline: auto;
}

.testimonials__head {
    justify-content: center;
}

.card.testimonial {
    position: relative;
    text-align: left;
    margin-top: 5rem;
}

.testimonial__avatar {
    width: 5rem;
    height: 5rem;
    border-radius: 1.5rem;
    overflow: hidden;
    position: absolute;
    top: -2.6rem;
    left: calc(50% - 2rem);
    box-shadow: 0 1.5rem 2rem rgba(26, 26, 54, 0.4);
    transition: var(--transition);
}

.testimonial:hover .testimonial__avatar {
    border-radius: 50%;
}

.testimonial__quote {
    font-style: italic;
    margin: 1rem 0 2rem;
}

.card.small.testimonial__title {
    margin-top: 0.3rem;
}

.testimonials__btn-container {
    width: fit-content;
    margin: 2.5rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.testimonial__btn {
    background: transparent;
    cursor: pointer;
}

.testimonial__btn svg {
    fill: var(--main-color);
    font-size: 1.8rem;
    width: 3rem;
    height: 3rem;
}


/*========================== 
Footer
=============================*/

footer {
    background-color: var(--sec-color);
    margin-top: 7rem;
    padding-top: 7rem;
    font-size: 0.9rem;
}

.footer__container {
    display: grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap: 6rem;
}

.footer__container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer__container article p {
    margin-top: 0.5rem;
}

.footer__container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
}

.fooeter__socials {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.fooeter__socials a {
    background: var(--sec-bkg);
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
}

.fooeter__socials a svg {
    color: var(--text-color);
}

.fooeter__socials a:hover {
    border-color: #fbbe5b;
    background: transparent;
}

.footer__copyright {
    color: var(--text-color);
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid rgb(214, 205, 205);
    margin-top: 5rem;
}


/*========================== 
media
=============================*/

@media screen and (max-width: 1024px) {
    .main__header {
        height: fit-content;
        padding: 12rem 0;
    }
    .main__header-container {
        gap: 0;
    }
    .main__header-circle {
        width: 16rem;
        height: 16rem;
    }
    /* programs */
    .programs__wrapper {
        grid-template-columns: 1fr 1fr;
    }
    /* Values */
    .values__container {
        grid-template-columns: 1fr;
        justify-content: center;
        gap: 4rem;
    }
    .values__wrapper {
        gap: 4rem 3rem;
    }
    /* Faqs */
    .faqs__wrapper {
        grid-template-columns: 1fr;
    }
    /* Testimonials*/
    .Testimonials {
        width: 80%;
    }
    /* footer */
    .footer__container {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 600px) {
    /*main-header*/
    .main__header {
        margin-top: 0;
        padding: 12rem 0;
        height: 100vh;
    }
    .main__header-right {
        margin-top: -3rem;
    }
    .main__header-container {
        grid-template-columns: 1fr;
    }
    /* programs */
    .programs {
        margin-top: 13rem;
    }
    .programs__wrapper {
        grid-template-columns: 1fr;
        gap: 2rem;
        margin-top: 3rem;
    }
    .card.programs__program {
        width: 90%;
        margin: 0 auto;
    }
    /*values*/
    .values__wrapper {
        grid-template-columns: 1fr;
    }
    .card.values__value {
        width: 90%;
        margin: 0 auto;
    }
    /*Testimonials*/
    .Testimonials {
        width: 90%;
        margin-top: 5rem;
    }
    /* footer */
    .footer__container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }
    .footer__container article {
        align-items: center;
    }
    .footer__container article p {
        text-align: center;
    }
}

@media screen and (max-width: 300px) {
    .main__header-right {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 400px) {
    .main__header-right {
        margin-top: 1rem;
    }
}