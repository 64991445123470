.contact__container {
    display: grid;
    place-items: center;
}

.contact__wrapper {
    width: fit-content;
    display: flex;
    gap: 2rem;
}

.contact__wrapper a {
    color: var(--text-color);
    background: var(--sec-color);
    font-size: 2rem;
    border: 2px solid transparent;
    padding: 2rem;
    border-radius: 1.5rem;
    transition: var(--transition);
}

.contact__wrapper a:hover {
    background: transparent;
    color: var(--main-color);
    border-color: var(--main-color);
}

@media screen and (max-width: 600px) {
    .contact__wrapper {
        gap: 1rem;
    }
    .contact__wrapper a {
        padding: 1rem;
        font-size: 1.2rem;
        border-radius: 1rem;
    }
}