.plans__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin-top: 4rem;
}

.plan h3 {
    margin-bottom: 0.8rem;
}

.plan small {
    margin-bottom: 2rem;
}

.plan h1 {
    display: inline-block;
    color: var(--text-color);
}

.plan h2 {
    display: inline-block;
    opacity: 0.5;
}

.plan h4 {
    margin-top: 2rem;
}

.plan p {
    margin-top: 1rem;
    color: var(--text-color);
}

.plan p.disabled {
    opacity: 0.5;
}

.plan button {
    margin-top: 3rem;
    cursor: pointer;
}

.card:nth-child(2) {
    border: 2px solid var(--main-color);
}

@media screen and (max-width: 1024px) {
    .plans__container {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-top: 4rem;
    }
}

@media screen and (max-width: 600px) {
    .plans__container {
        grid-template-columns: 1fr;
    }
    .plan {
        width: 90%;
        margin-inline: auto;
    }
}